<template>
  <div>
    <b-overlay
      :show="show"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >

      <IbClientTabs />
      <b-card>
        <b-row class="p-1">
          <b-col
            sm="12"
          >
            <div class="d-flex">
              <div class="font-weight-bolder">
                {{ $t('firstName_lastName') }}:
              </div>
              <div class="user-profile">
                {{ client.name }}
              </div>
            </div></b-col>
        </b-row>
        <div class="d-md-flex">
          <div>
            <b-form-group>
              <div
                class="d-flex relative "
              >
                <flat-pickr
                  ref="register-date"
                  v-model="filter.date"
                  class="form-control"
                  name="to-expiration"
                  :placeholder="$t('date')"
                  autocomplete="nope"
                  :clearable="true"
                  :config="flatPickerOptions"
                />
                <b-button
                  style="height: 38px;"
                  class="absoloute"
                  @click="clearDate()"
                >
                  <feather-icon
                    icon="XIcon"
                    class=""
                    size="15"
                  />
                </b-button>
              </div>
            </b-form-group>
          </div>
          <div class="w-100">
            <b-button
              style=""
              class="mb-2 mb-d-0 w-100 w-md-25 mx-0 mx-md-1"
              @click="search()"
            >
              {{ $t('search') }}
            </b-button>
          </div>
        </div>
        <b-row>
          <b-col
            v-for="detail in clients"
            :key="detail.id"
            lg="3"
            md="6"
            sm="12"
          >
            <statisticCard
              :client="detail"
              :show="true"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BIconCoin, BIconBookmarkCheck, BIconCartDash, BIconCalculator,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'
import IbClientTabs from '../components/ibClientTabs.vue'
import PannelIbApis from '@/modules/IB/services/apis'
import statisticCard from '@/modules/IB/views/components/statisticCard.vue'
import 'flatpickr/dist/plugins/monthSelect/style.css'
// eslint-disable-next-line import/order
import moment from 'moment'

const ib = new PannelIbApis()
export default {
  components: {
    BCol,
    BRow,
    BCard,
    IbClientTabs,
    statisticCard,
    flatPickr,
  },
  data() {
    const now = new Date()
    const date = moment(now).format('YYYY-MM-DD')
    return {
      show: true,
      client: {
        name: '',
        total_balance: '',
        total_commission: '',
        total_equity: '',
        total_lot: '',
      },
      flatPickerOptions: {
        plugins: [
          new MonthSelectPlugin({
            shorthand: true, // defaults to false
            dateFormat: 'Y-m', // defaults to "F Y"
            altFormat: 'F Y', // defaults to "F Y"
            theme: 'dark', // defaults to "light"
          }),
        ],
      },
      filter: {
        date,
      },
    }
  },
  computed: {
    clients() {
      return [
        {
          title: this.$t('ib-balance'),
          text: this.client.total_balance ? this.client.total_balance.toFixed(2) : 0,
          icon: BIconCoin,
          color: '#01c92d',
        },
        {
          title: this.$t('forex.equity'),
          text: this.client.total_equity ? this.client.total_equity.toFixed(2) : 0,
          icon: BIconBookmarkCheck,
          color: '#ff9700',
        },
        {
          title: this.$t('traded_lots'),
          text: this.client.total_lot ? this.client.total_lot.toFixed(2) : 0,
          icon: BIconCartDash,
          color: '#8c76fd',
        },
        {
          title: this.$t('total-commission'),
          text: this.client.total_commission ? this.client.total_commission.toFixed(2) : 0,
          icon: BIconCalculator,
          color: '#50adff',
        },
      ]
    },
  },

  async mounted() {
    await this.getData()
  },
  methods: {
    async getData() {
      this.show = true
      await ib.clientDetail(this.$route.params.userID, { date: this.filter.date }).then(res => {
        this.client = res.data.results
      }).finally(() => {
        this.show = false
      })
    },
    search() {
      this.getData()
    },
    clearDate() {
      const datePickerWrap = this.$refs['register-date']
      if (!datePickerWrap) {
        return
      }
      datePickerWrap.fp.clear()
    },
  },
}
</script>
<style scoped>
    .user-profile{
        margin-left: 0.5rem;
    }
</style>
